import type { FC } from "react";
import type { MatchCardsListComponent } from "@motain/xpa-proto-files-web/lib/types/match_cards_list";
import { SectionHeader } from "@/xpa-components/section-header/SectionHeader";
import { isNonNullable } from "@/types/isNonNullable";
import { MatchCardComponent } from "@/xpa-components/match-card/MatchCard";
import styles from "./MatchCardsList.module.scss";
import React from "react";
import classNames from "classnames";
import { LinkWithArrow } from "@/components/link-with-arrow/LinkWithArrow";
export const MatchCardsList: FC<MatchCardsListComponent> = (props) => {
  const { sectionHeader, matchCards, link } = props;
  return (
    <div>
      {isNonNullable(sectionHeader) && (
        <SectionHeader {...sectionHeader}></SectionHeader>
      )}
      <ul className={classNames("grid", styles.matches)}>
        {isNonNullable(matchCards) &&
          matchCards.map((card) => {
            return (
              <li key={card.uiKey}>
                <MatchCardComponent {...card}></MatchCardComponent>
              </li>
            );
          })}
      </ul>
      {isNonNullable(link) && (
        <LinkWithArrow className={styles.link} {...link}></LinkWithArrow>
      )}
    </div>
  );
};
