import type { FC } from "react";
import type { FormGuideMatch as XpaFormGuideMatch } from "@motain/xpa-proto-files-web/lib/types/form_guide";
import { FormGuideMatchOutcomeValue } from "@motain/xpa-proto-files-web/lib/types/form_guide";
import { ImageWithFallback } from "@/components/image/ImageWithFallback";
import { isNullable } from "@/types/isNullable";
import Link from "next/link";
import { trackingService } from "@/modules/analytics";
import { EventType } from "@motain/xpa-proto-files-web/lib/types/tracking";
import { isNonNullable } from "@/types/isNonNullable";
import styles from "./FormGuideMatch.module.scss";
import classNames from "classnames";

const outcomeColor = (outcome: FormGuideMatchOutcomeValue | undefined) => {
  switch (outcome) {
    case FormGuideMatchOutcomeValue.OUTCOME_WIN:
      return styles.win;
    case FormGuideMatchOutcomeValue.OUTCOME_LOSS:
      return styles.loss;
    case FormGuideMatchOutcomeValue.OUTCOME_DRAW:
      return styles.draw;
    default:
      return "";
  }
};

export const FormGuideMatch: FC<XpaFormGuideMatch> = (props) => {
  const {
    homeTeam,
    awayTeam,
    outcome,
    dateFormatted,
    trackingEvents,
    link,
    dateUtc,
  } = props;

  if (
    isNullable(homeTeam) ||
    isNullable(awayTeam) ||
    (isNullable(homeTeam.isTeamToHide) && isNullable(awayTeam.isTeamToHide)) ||
    homeTeam.isTeamToHide === awayTeam.isTeamToHide // Only 1 team should be hidden at the time
  ) {
    return null;
  }

  return (
    <Link
      data-testid="container"
      className={classNames(styles.container, outcomeColor(outcome?.value))}
      href={link}
      onClick={() => {
        trackingService.sendXpaTracking(trackingEvents, EventType.EVENT_CLICK);
      }}
    >
      <div
        className={classNames(
          styles.match,
          awayTeam.isTeamToHide ? styles.matchHiddenAway : "",
          homeTeam.isTeamToHide ? styles.matchHiddenHome : "",
        )}
      >
        {isNonNullable(outcome) && (
          <div className={styles.outcomeWrapper}>
            <p className={classNames("label", styles.outcome)}>
              <span className={styles.outcomeFull}>{outcome.text}</span>
              <span className={styles.outcomeShort}>
                {outcome.text.charAt(0)}
              </span>
            </p>
          </div>
        )}
        <p className={classNames("title-7-bold", styles.homeScore)}>
          {homeTeam.score}
        </p>
        <div className={classNames("title-7-bold", styles.line)}>-</div>
        <p className={classNames("title-7-bold", styles.awayScore)}>
          {awayTeam.score}
        </p>
        <ImageWithFallback
          width={28}
          height={28}
          className={classNames(
            homeTeam.isTeamToHide ? styles.hide : "",
            styles.homeTeam,
          )}
          src={homeTeam.imageObject?.path ?? ""}
          darkModeSrc={homeTeam.imageObject?.darkModePath}
          isPreloaded={homeTeam.imageObject?.isPreloaded}
          fallbackImage="https://images.onefootball.com/cw/icons/entity-logo-placeholder.svg"
          alt={homeTeam.name}
        ></ImageWithFallback>
        <ImageWithFallback
          width={28}
          height={28}
          className={classNames(
            awayTeam.isTeamToHide ? styles.hide : "",
            styles.awayTeam,
          )}
          src={awayTeam.imageObject?.path ?? ""}
          darkModeSrc={awayTeam.imageObject?.darkModePath}
          isPreloaded={awayTeam.imageObject?.isPreloaded}
          fallbackImage="https://images.onefootball.com/cw/icons/entity-logo-placeholder.svg"
          alt={awayTeam.name}
        ></ImageWithFallback>
      </div>

      <time className={classNames("text-4", styles.date)} dateTime={dateUtc}>
        {dateFormatted}
      </time>
    </Link>
  );
};
