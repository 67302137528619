import type { ChangeEvent, FC } from "react";
import React from "react";
import classnames from "classnames";

import styles from "./QueryParamSelector.module.scss";
import type { QueryParamSelectorComponent } from "@motain/xpa-proto-files-web/lib/types/query_param_selector";
import { getCurrentValue } from "./getCurrentValue";
import { useQueryParamHandler } from "./hooks/useQueryParamHandler";

export const QueryParamSelector: FC<QueryParamSelectorComponent> = ({
  label,
  queryParam,
  options,
}) => {
  const { selectedOption, handleSelectChange } =
    useQueryParamHandler(queryParam);

  return (
    <section className={styles.container}>
      <label className={styles.label}>
        <span className="screen-reader-only">{label}</span>
        <svg
          className={styles.openIcon}
          viewBox="0 0 12 7"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M11 0.892578L6 4.99971L1 0.892578" />
        </svg>
        <select
          className={classnames("title-6-regular", styles.select)}
          onChange={(event: ChangeEvent<HTMLSelectElement>) => {
            handleSelectChange(event.target.value);
          }}
          value={getCurrentValue(options, selectedOption)}
        >
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </label>
    </section>
  );
};
