import styles from "./Standing.module.scss";
import classNames from "classnames";
import type { StandingsRow } from "@motain/xpa-proto-files-web/lib/types/standings";
import { StandingsRow_PositionChange } from "@motain/xpa-proto-files-web/lib/types/standings";
import { ImageWithFallback } from "@/components/image/ImageWithFallback";
import { isNonNullable } from "@/types/isNonNullable";
import { EntityLogo } from "@/components/entity-logo/EntityLogo";
import useTranslation from "next-translate/useTranslation";

export const RowContent: React.FC<StandingsRow> = (row) => {
  const { t } = useTranslation("web-payments");

  return (
    <>
      <div className={classNames(styles.standings__cell)}>
        {row.position > 0 ? <span>{row.position}</span> : <span>-</span>}
      </div>
      {(() => {
        switch (row.positionChange) {
          case StandingsRow_PositionChange.UP:
            return (
              <div
                className={classNames(
                  styles.standings__cell,
                  styles.standings__cellIcon,
                )}
                title={t`STANDINGS_POSITION_UP`}
              >
                <ImageWithFallback
                  className={styles.standings__icon}
                  fallbackImage={null}
                  width={12}
                  height={12}
                  alt={t`STANDINGS_POSITION_UP`}
                  src={"https://images.onefootball.com/cw/icons/arrow-up.svg"}
                  darkModeSrc={
                    "https://images.onefootball.com/cw/icons/arrow-up.svg"
                  }
                />
              </div>
            );

          case StandingsRow_PositionChange.DOWN:
            return (
              <div
                className={classNames(
                  styles.standings__cell,
                  styles.standings__cellIcon,
                )}
                title={t`STANDINGS_POSITION_DOWN`}
              >
                <ImageWithFallback
                  className={styles.standings__icon}
                  fallbackImage={null}
                  width={12}
                  height={12}
                  alt={t`STANDINGS_POSITION_DOWN`}
                  src={"https://images.onefootball.com/cw/icons/arrow-down.svg"}
                  darkModeSrc={
                    "https://images.onefootball.com/cw/icons/arrow-down.svg"
                  }
                />
              </div>
            );
          default:
            return (
              <div
                className={classNames(
                  styles.standings__cell,
                  styles.standings__cellIcon,
                )}
                title={t`STANDINGS_POSITION_NO_CHANGES`}
              >
                <div
                  className={classNames(
                    styles.standings__icon,
                    styles.standings__iconNoChanges,
                  )}
                  aria-label={t`STANDINGS_POSITION_NO_CHANGES`}
                ></div>
              </div>
            );
        }
      })()}
      <div className={styles.standings__team}>
        {isNonNullable(row.imageObject) && (
          <EntityLogo
            src={row.imageObject.path}
            alt={row.imageObject.alt}
            className={styles.standings__teamLogo}
          ></EntityLogo>
        )}
        <p className={classNames("title-7-medium", styles.standings__teamName)}>
          {row.teamName}
        </p>
      </div>

      <div
        className={classNames(
          styles.standings__cell,
          styles.standings__cellTextDimmed,
        )}
      >
        {row.playedMatchesCount}
      </div>

      <div
        className={classNames(
          styles.standings__cell,
          styles.standings__cellLargeScreen,
          styles.standings__cellTextDimmed,
        )}
      >
        {row.wonMatchesCount}
      </div>

      <div
        className={classNames(
          styles.standings__cell,
          styles.standings__cellLargeScreen,
          styles.standings__cellTextDimmed,
        )}
      >
        {row.drawnMatchesCount}
      </div>

      <div
        className={classNames(
          styles.standings__cell,
          styles.standings__cellLargeScreen,
          styles.standings__cellTextDimmed,
        )}
      >
        {row.lostMatchesCount}
      </div>

      <div
        className={classNames(
          styles.standings__cell,
          styles.standings__cellTextDimmed,
        )}
      >
        {row.goalsDiff}
      </div>

      <div className={classNames(styles.standings__cell)}>
        <span className="title-7-bold">{row.points}</span>
      </div>
    </>
  );
};
