import type { FC } from "react";
import type { FormGuide as XpaFormGuide } from "@motain/xpa-proto-files-web/lib/types/form_guide";
import { FormGuideMatch } from "./form-guide-match/FormGuideMatch";
import styles from "./FormGuide.module.scss";
import { isNullable } from "@/types/isNullable";
import { isNonNullable } from "@/types/isNonNullable";
import { Title } from "@/xpa-components/title/Title";

export const FormGuide: FC<XpaFormGuide> = (props) => {
  const { matches, headerTitle } = props;

  if (isNullable(matches)) {
    return null;
  }

  return (
    <section className={styles.container}>
      {isNonNullable(headerTitle) && <Title align="left" {...headerTitle} />}
      <ul className={styles.list}>
        {matches.map((match) => {
          return (
            <li key={match.uiKey}>
              <FormGuideMatch {...match} />
            </li>
          );
        })}
      </ul>
    </section>
  );
};
