import { useState } from "react";
import { useRouter } from "next/router";
import { remoteLogger } from "@/utils/remoteLogger";
import { isNullable } from "@/types/isNullable";

export const useQueryParamHandler = (
  queryParam: string,
): { selectedOption: string; handleSelectChange: (value: string) => void } => {
  const router = useRouter();
  const [selectedOption, setSelectedOption] = useState<string>("");
  const [queryParams, setQueryParams] = useState(router.query);

  const handleSelectChange = (value: string): void => {
    const queryValue = value === "" ? null : value;

    let updatedQueryParams;

    if (isNullable(queryValue)) {
      const { [queryParam]: _, ...restParams } = queryParams;
      updatedQueryParams = restParams;
    } else {
      updatedQueryParams = { ...queryParams, [queryParam]: queryValue };
    }

    setSelectedOption(value);
    setQueryParams(updatedQueryParams);

    const updatedPathname = {
      pathname: router.pathname,
      query: updatedQueryParams,
    };

    router
      .replace(updatedPathname, undefined, { shallow: false })
      .catch((error) => {
        remoteLogger.error(error);
      });
  };

  return { selectedOption, handleSelectChange };
};
