import type { Layout } from "@motain/xpa-proto-files-web/lib/runtime/xpa";
import type { GetServerSideProps, InferGetServerSidePropsType } from "next";
import { fetchXpaLayoutWithResponseInfo } from "@/utils/fetchXpaLayout";
import type { FC } from "react";
import type { XpaSupportedComponentsResolverProps } from "@/components/xpa-layout/XpaLayout";
import { XpaLayout } from "@/components/xpa-layout/XpaLayout";
import { Title } from "@/xpa-components/title/Title";
import { HorizontalSeparator } from "@/xpa-components/horizontal-separator/HorizontalSeparator";
import { RelatedVideos } from "@/xpa-components/related-videos/RelatedVideos";
import { Gallery } from "@/xpa-components/gallery/Gallery";
import styles from "@/styles/pages/News.module.scss";
import { UpcomingMatches } from "@/xpa-components/upcoming-matches/UpcomingMatches";
import { EntityTitle } from "@/xpa-components/entity-title/EntityTitle";
import { PageTabs } from "@/xpa-components/page-tabs/PageTabs";
import { EmptyState } from "@/xpa-components/empty-state/EmptyState";
import { XpaGoogleAdsPlaceholder } from "@/modules/ads";
import type { EntityStreamName } from "@/modules/analytics";
import {
  StreamName,
  useRegisterStream,
  useRegisterXpaStream,
} from "@/modules/analytics";

import { EntityNavigationComponent } from "@/xpa-components/entity-navigation/EntityNavigation";
import { Standings } from "@/xpa-components/standings/Standings";
import { MatchCardsList } from "@/xpa-components/match-cards-list/MatchCardsList";
import { QueryParamSelector } from "@/xpa-components/query-param-selector/QueryParamSelector";
import { EmbeddedVideoPlayer } from "@/xpa-components/embedded-video-player/EmbeddedVideoPlayer";
import { VideoGallery } from "@/xpa-components/video-gallery/VideoGallery";
import { FormGuide } from "@/xpa-components/form-guide/FormGuide";

const Teams: FC<InferGetServerSidePropsType<typeof getServerSideProps>> = (
  props,
) => {
  const streamName: EntityStreamName =
    props.metadata?.streamName ?? StreamName.unknown;

  useRegisterStream(streamName);
  useRegisterXpaStream(props);

  return (
    <div className={styles.xpaContainer}>
      <XpaLayout
        layout={props}
        XpaSupportedComponentsResolver={XpaSupportedComponentsResolver}
      />
    </div>
  );
};

export const getServerSideProps: GetServerSideProps<Layout> = async (ctx) => {
  const layoutResponse = await fetchXpaLayoutWithResponseInfo(ctx);

  if (layoutResponse.status === 404) {
    return { notFound: true };
  } else {
    return { props: layoutResponse.layout };
  }
};

export default Teams;

const XpaSupportedComponentsResolver: XpaSupportedComponentsResolverProps = ({
  contentType,
}) => {
  switch (contentType.$case) {
    case "embeddedVideoPlayer":
      return <EmbeddedVideoPlayer {...contentType.embeddedVideoPlayer} />;
    case "emptyState":
      return <EmptyState {...contentType.emptyState} />;
    case "entityNavigation":
      return <EntityNavigationComponent {...contentType.entityNavigation} />;
    case "entityTitle":
      return <EntityTitle {...contentType.entityTitle} />;
    case "formGuide":
      return <FormGuide {...contentType.formGuide} />;
    case "gallery":
      return <Gallery {...contentType.gallery} />;
    case "googleAdsPlaceholder":
      return <XpaGoogleAdsPlaceholder {...contentType.googleAdsPlaceholder} />;
    case "horizontalSeparator":
      return <HorizontalSeparator {...contentType.horizontalSeparator} />;
    case "matchCardsList":
      return <MatchCardsList {...contentType.matchCardsList} />;
    case "queryParamSelector":
      return <QueryParamSelector {...contentType.queryParamSelector} />;
    case "relatedVideos":
      return <RelatedVideos {...contentType.relatedVideos} />;
    case "standings":
      return <Standings {...contentType.standings} />;
    case "tabs":
      return <PageTabs {...contentType.tabs} />;
    case "title":
      return <Title {...contentType.title} />;
    case "upcomingMatches":
      return <UpcomingMatches {...contentType.upcomingMatches} />;
    case "videoGallery":
      return <VideoGallery {...contentType.videoGallery} />;
    default:
      return null;
  }
};
