import type { StandingsComponent } from "@motain/xpa-proto-files-web/lib/types/standings";
import type { FC } from "react";
import { useCallback } from "react";
import { isNonNullable } from "@/types/isNonNullable";
import styles from "./Standing.module.scss";
import classNames from "classnames";
import { EventType } from "@motain/xpa-proto-files-web/lib/types/tracking";
import { trackingService } from "@/modules/analytics";
import useTranslation from "next-translate/useTranslation";
import { RowContent } from "./RowContent";
import { LinkWithArrow } from "@/components/link-with-arrow/LinkWithArrow";
import Link from "next/link";

const headerCells = [
  {
    abbr: "STANDINGS_GAMES_PLAYED_ABBR",
    description: "STANDINGS_GAMES_PLAYED_DESCRIPTION",
    isLargeScreenOnly: false,
    isDimmed: true,
    id: 0,
  },
  {
    abbr: "STANDINGS_GAMES_WON_ABBR",
    description: "STANDINGS_GAMES_WON_DESCRIPTION",
    isLargeScreenOnly: true,
    isDimmed: true,
    id: 1,
  },
  {
    abbr: "STANDINGS_GAMES_DRAWN_ABBR",
    description: "STANDINGS_GAMES_DRAWN_DESCRIPTION",
    isLargeScreenOnly: true,
    isDimmed: true,
    id: 2,
  },
  {
    abbr: "STANDINGS_GAMES_LOST_ABBR",
    description: "STANDINGS_GAMES_LOST_DESCRIPTION",
    isLargeScreenOnly: true,
    isDimmed: true,
    id: 3,
  },
  {
    abbr: "STANDINGS_GOAL_DIFF_ABBR",
    description: "STANDINGS_GOAL_DIFF_DESCRIPTION",
    isLargeScreenOnly: false,
    isDimmed: true,
    id: 4,
  },
  {
    abbr: "STANDINGS_POINTS_ABBR",
    description: "STANDINGS_POINTS_DESCRIPTION",
    isLargeScreenOnly: false,
    isDimmed: false,
    id: 5,
  },
] as const;

export const Standings: FC<StandingsComponent> = (props) => {
  const { t } = useTranslation("web-payments");

  const handleRowLinkClick = useCallback(
    (trackingEvents: StandingsComponent["rows"][number]["trackingEvents"]) => {
      trackingEvents.forEach((trackingEvent) => {
        if (trackingEvent.type === EventType.EVENT_CLICK) {
          trackingService.sendSingleXpaTracking(trackingEvent);
        }
      });
    },
    [],
  );

  return (
    <section className={styles.standings}>
      <article className={styles.standings__tableWrapper}>
        <ul>
          <li
            className={classNames(
              styles.standings__row,
              styles.standings__rowGrid,
            )}
            key={`${props.link?.name ?? ""}-${props.title}`}
          >
            {isNonNullable(props.link) ? (
              <a
                aria-label={props.link.name}
                href={props.link.urlPath}
                className={classNames(
                  styles.label,
                  styles.standings__tableHeaderText,
                )}
              >
                {props.link.name}
              </a>
            ) : (
              <p
                className={classNames(
                  "label",
                  styles.standings__tableHeaderText,
                )}
              >
                {props.title}
              </p>
            )}

            {headerCells.map((headerCell) => (
              <div
                key={headerCell.id}
                className={classNames(
                  headerCell.isLargeScreenOnly &&
                    styles.standings__cellLargeScreen,

                  styles.standings__cell,
                  "title-7-bold",
                )}
              >
                <abbr
                  className={classNames(
                    "title-7-medium",
                    headerCell.isDimmed && styles.standings__cellTextDimmed,
                  )}
                  title={t(headerCell.description)}
                  style={{ textDecoration: "none" }}
                  // overwrites user agent styling
                >
                  {t(headerCell.abbr)}
                </abbr>
              </div>
            ))}
          </li>

          {/*Table Rows*/}

          {props.rows.map((row, index) => {
            const isMissingRow =
              index > 0 &&
              row.position > 2 &&
              row.position - (props.rows[index - 1]?.position ?? 0) > 1;

            return (
              <div key={row.uiKey}>
                {isMissingRow && (
                  <li className={styles.standings__missingRows}>
                    <svg
                      className={styles.standings__missingRowsIcon}
                      fill="none"
                      viewBox="0 0 18 4"
                    >
                      <circle cx="2" cy="2" fill="currentColor" r="2" />
                      <circle cx="9" cy="2" fill="currentColor" r="2" />
                      <circle cx="16" cy="2" fill="currentColor" r="2" />
                    </svg>
                  </li>
                )}
                <li
                  key={row.uiKey}
                  className={classNames(
                    styles.standings__row,
                    row.teamPath != "" && styles.standings__rowLink,
                  )}
                >
                  {row.teamPath != "" ? (
                    <Link
                      className={styles.standings__rowGrid}
                      onClick={() => {
                        handleRowLinkClick(row.trackingEvents);
                      }}
                      href={row.teamPath}
                      aria-label={row.teamName}
                    >
                      <RowContent {...row} />
                    </Link>
                  ) : (
                    <div className={styles.standings__rowGrid}>
                      <RowContent {...row} />
                    </div>
                  )}
                </li>
              </div>
            );
          })}
        </ul>

        {isNonNullable(props.fullTableLink) && (
          <LinkWithArrow {...props.fullTableLink} />
        )}
      </article>
    </section>
  );
};
