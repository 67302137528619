import { isNonNullable } from "@/types/isNonNullable";
import type { QueryParamSelectorOption } from "@motain/xpa-proto-files-web/lib/types/query_param_selector";

export const getCurrentValue = (
  currentOptions: Array<QueryParamSelectorOption>,
  value: string,
): string | undefined => {
  const currentOption = currentOptions.find((option) => option.value === value);

  if (isNonNullable(currentOption)) {
    return currentOption.value;
  }

  return undefined;
};
